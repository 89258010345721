import React, { useState } from "react";
import { FaInfoCircle, FaPlus, FaMinus } from "react-icons/fa";
import $ from 'jquery';
import ReactSpeedometer from "react-d3-speedometer";
import CardBoxs from "./CardBoxs";

const RiskProfile = (props) => {
  const [TogglePlusIons, setTogglePlusIons] = useState(true);
  const CollapseButton = () => {
    setTogglePlusIons((prev) => !prev)
    $("#RiskAnalysis_sections_id").slideToggle(0);
  };

  return (
  
      <div>
      <div id="risk_profile">
      <div className="Basic_Informations" style={{display:"flex",justifyContent:"space-between",alignItems:"center",alignContent:"center"}}>
          <div>{props.title}</div>
          <div className="toggleIonsPlusicons" style={{cursor:"pointer",paddingRight:'10px'}} onClick={CollapseButton}>
            {TogglePlusIons ? <FaMinus color="#c1c1c1" fontSize={20} /> : <FaPlus color="#c1c1c1" fontSize={20}/>}
            </div>
        </div>        
        
        <br/>

        <div id="RiskAnalysis_sections_id">
        <div  className="RiskAnalysis_sections">
          
          <div className="Security_Rating_Score">
            <span className="flexbody">
              <span>Security Rating Score</span>
              <span className="grid_body">
                <FaInfoCircle className="infoCircleico" fontSize={18} />
                <span className="tooltiptext">BitSight</span>
              </span>
            </span>
          </div>

          <div className="Security_Rating_Score">
            <span className="flexbody">
              <span> Inherent Risk</span>
              <span className="grid_body">
                <FaInfoCircle className="infoCircleico" fontSize={18} />
                <span className="tooltiptext">Archer</span>
              </span>
            </span>
          </div>

          <div className="Security_Rating_Score">
            <span className="flexbody">
              <span> Residual Risk</span>
              <span className="grid_body">
                <FaInfoCircle className="infoCircleico" fontSize={18} />
                <span className="tooltiptext">Archer</span>
              </span>
            </span>
          </div>

        </div>
        <div className="RiskAnalysis_sections">
          <div className="items_card_risk items_card_risk_1">
           

           <div className="CardBox_details_1">
              <ReactSpeedometer
                className="ReactSpeedometer_class"
                height={200}
                needleHeightRatio={0.7}
                value={props.BitsightScore}
                customSegmentStops={[0, 150, 350, 600, 800, 1000]}
                segmentColors={["red", "#ff4214", "orange", "#00b610", "green"]}

                currentValueText={props.BitsightScoreText}
                customSegmentLabels={[
                  {
                    text: " 0 ",
                    position: "OUTSIDE",
                    color: "red",
                  },
                  {
                    text: "150 ",
                    position: "OUTSIDE",
                    color: "#ff4214",
                  },
                  {
                    text: "350",
                    position: "OUTSIDE",
                    color: "orange",
                  },
                  {
                    text: "600 ",
                    position: "OUTSIDE",
                    color: "#00b610",
                  },
                  {
                    text: "800",
                    position: "OUTSIDE",
                    color: "green",

                  },
                ]}
                ringWidth={47}
                needleTransitionDuration={3333}
                needleTransition="easeElastic"
                needleColor={"blue"}
                textColor={"black"}
              />
            </div>
          </div>

          <div className="items_card_risk">
            <div className="CardBox_details_1">
              <ReactSpeedometer
                className="ReactSpeedometer_class"
                height={200}
                needleHeightRatio={0.7}
                value={props.ValueLow}
                customSegmentStops={[0, 150, 350, 600, 800, 1000]}
                segmentColors={["#00b610", "green", "orange", "#ff4214", "red"]}
                currentValueText={props.ISF_Inherent_Risk_Rating}
                customSegmentLabels={[
                  {
                    text: " Very Low ",
                    position: "OUTSIDE",
                    color: "#00b610",
                  },
                  {
                    text: "Low ",
                    position: "OUTSIDE",
                    color: "green",
                  },
                  {
                    text: "Modrate",
                    position: "OUTSIDE",
                    color: "Orange",
                  },
                  {
                    text: "High ",
                    position: "OUTSIDE",
                    color: "#ff4214",
                  },
                  {
                    text: "Critical",
                    position: "OUTSIDE",
                    color: "red",
                  },
                ]}
                ringWidth={47}
                needleTransitionDuration={3333}
                needleTransition="easeElastic"
                needleColor={"blue"}
                textColor={"black"}
              />
            </div>
          </div>

          <div className="items_card_risk">
            <div className="CardBox_details_1">
              <ReactSpeedometer
                height={200}
                needleHeightRatio={0.7}
                value={props.inherentRisk}
                customSegmentStops={[0, 150, 350, 600, 800, 1000]}
                segmentColors={["#00b610", "green", "orange", "#ff4214", "red"]}
                currentValueText={props.Risk_Rating}
                customSegmentLabels={[
                  {
                    text: " Very Low ",
                    position: "OUTSIDE",
                    color: "#00b610",
                  },
                  {
                    text: "Low ",
                    position: "OUTSIDE",
                    color: "green",
                  },
                  {
                    text: "Modrate",
                    position: "OUTSIDE",
                    color: "Orange",
                  },
                  {
                    text: "High ",
                    position: "OUTSIDE",
                    color: "#ff4214",
                  },
                  {
                    text: "Critical",
                    position: "OUTSIDE",
                    color: "red",
                  },
                ]}
                ringWidth={47}
                needleTransitionDuration={3333}
                needleTransition="easeElastic"
                needleColor={"blue"}
                textColor={"black"}
              />
            </div>
          </div>
        </div>

        </div>
        

        {TogglePlusIons ? <span></span> : <span>Please click the right ( + ) icons for view the {props.title}</span>}

      </div>
    </div>
  );
};

export default RiskProfile;
