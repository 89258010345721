import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import "../../App.css";
import "../../css/Style.css"
import "../../css/Login.css"
import bgimage from "../../img/Aays-login-page-img-2.jpg";
import logo from "../../img/Aays.png";
import logo6 from "../../img/V6.png";


import Home from '../pages/Homepage';

export default withOktaAuth(
  class SigninWidget extends Component {
    constructor(props) {
      super(props);
      this.login = this.login.bind(this);
      this.logout = this.logout.bind(this);
    }

    async login() {
      await this.props.oktaAuth.signInWithRedirect();
    }

    async logout() {
      await this.props.oktaAuth.signOut();
    }

    render() {
      let body = null;
      if (this.props.authState?.isAuthenticated) {
        body = (
          <div className="Buttons">
            <Home/>
          </div>
        );
      } else {
        body = (
          <div className="Buttons">
            <div className="loginPage">
              <div className="login_body_content">
                <div className="bg-white dark:bg-gray-900 bgColor">
                  <div className="login_body_grid">
                    <div style={{ backgroundImage: `url(${bgimage})`}} className="hidden bg-cover lg:block lg:w-2/3 login_bg_image">
                      <div className="login_info">
                        <div>

                        
                          <h2 className="text-4xl font-bold text-white">
                          V Insight Group Community
                          </h2>

                          <p className="left_grid_text">
                              Welcome to the V-Insight application powered by Risk Management. Access to this system is limited - please contact <a  href="mailto:anilkumar@aaysinsight.com"><mark>anilkumar@aaysinsight.com</mark></a> for details.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="right_grid_align bgColor">
                      <div className="flex-1">
                        <div className="text-center">
                          <span className="text-4xl font-bold text-center text-gray-700 dark:text-white MarsLogo">
                            <img width={150} src={logo} alt="img" />
                          </span>

                          <p className="left_grid_text left_margin">
                            Sign in to access your account
                          </p>
                        </div>

                        <div className="mt-8">
                          <div className="mt-6">
                            <button
                              className="btn_login"
                              onClick={this.login}>
                              Sign in
                            </button>
                          </div>
                        </div>

                        <div style={{width:"70%",paddingTop:"1rem",display:"block",margin:"auto"}}>
                        <span id="Login_herf" style={{color:"white",display:"flex",justifyContent:"space-between"}}>
                          <a rel="noreferrer" target={"_blank"} style={{color:"white",textDecoration:"none"}} href="mailto:anilkumar@aaysinsight.com?subject=Need Access to the V-Insight Applications!"> Need Access?</a>

                          <a rel="noreferrer" target={"_blank"} style={{color:"white",textDecoration:"none"}} href="mailto:anilkumar@aaysinsight.com?subject=Need Help On Sign in On V-Insight Applications!"> Need Help?</a>
                          </span>
                      </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      return <div className="App">{body}</div>;
    }
  }
);
