import React, { useEffect, useRef, useState } from 'react'
import Popup from "reactjs-popup";
import { useReactToPrint } from "react-to-print";
import Logo from "../../img/WaterMark.png";
import { Link } from 'react-router-dom';

const contentStyle = {
  maxWidth: "1000px",
  width: "100%",
  background:"white",
  borderRadius: "20px"
};

// const VendorPageWrapper = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const Compareing = (props) => {
  const [FirstVendorDetails, setFirstVendorDetails] = useState([
    {
        "VendorName": "guangzhou wincustomer digital techn",
        "VendorID": "15214391",
        "VendorName_Region": "guangzhou wincustomer digital techn(Guangdong)",
        "Risk_Rating": "High",
        "ISF_Inherent_Risk_Rating": "High",
        "MSA_Completion_Date": 1711843200000,
        "Counts_Of_Close_Contracts": "3.0",
        "Counts_Of_Open_Contracts": "50.0",
        "Address": "refer to International version, GUANGZHOU, None, None, 510000, Guangdong, China, None",
        "Description_of_Project": "NA_in_Archer",
        "Vendor_URL": "NA_in_Archer",
        "Vendor_Category": "NA_in_Archer",
        "Vendor_Contact": "NA_in_Archer",
        "Vendor_Contact_EmailID": "NA_in_Archer",
        "Business_Partner_EmailID": "NA_in_Archer",
        "Owner": "NA_in_Ariba",
        "BitsightScore": "670",
        "VendorURL": "NA_in_Snow",
        "Segment_Association": "NA_in_Snow",
        "Vendor_Account_Manager_ContactName": "NA_in_Snow",
        "Vendor_Account_ManagerEmail": "NA_in_Snow",
        "Vendor_ContactName": "NA_in_Snow",
        "VendorEmail": "NA_in_Snow",
        "Description_of_Services": "NA_in_Snow",
        "Category": "NA_in_Snow",
        "MarsCommercialContactEmails": "NA_in_Snow",
        "MarsCommercialContactName": "NA_in_Snow",
        "Technical_Contact_Name": "NA_in_Snow",
        "Technical_Contact_Email": "NA_in_Snow",
        "Segment": "NA_in_Snow",
        "Finished_Goods_Value_Close_Contracts": "130788.33",
        "Finished_Goods_Value_Open_Contracts": "0.0",
        "SourceSystem": "SAP_APP",
        "Region": "Guangdong",
        "CountryName": "China",
        "Archer_VendorName": "NA",
        "Archer_SimilarityScore": "NA",
        "Ariba_VendorName": "NA_in_Ariba",
        "Ariba_SimilarityScore": "NA_in_Ariba",
        "Bitsight_VendorName": "NA_in_BitSight",
        "Bitsight_SimilarityScore": "NA_in_BitSight",
        "Snow_VendorName": "NA_in_Snow",
        "Snow_SimilarityScore": "NA_in_Snow",
        "VendorNameCombTranslation": "guangzhou wincustomer digital techn(Guangdong)",
        "Year": 2024,
        "Period": 4
    }
]);
  const [SecondVendorDetails, setSecondVendorDetails] = useState([
    {
        "VendorName": "shinnippon calendar co.  ltd. peppy",
        "VendorID": "15202466",
        "VendorName_Region": "shinnippon calendar co.  ltd. peppy",
        "Risk_Rating": "Low",
        "ISF_Inherent_Risk_Rating": "Low",
        "MSA_Completion_Date": 1711843200000,
        "Counts_Of_Close_Contracts": "0.0",
        "Counts_Of_Open_Contracts": "70.0",
        "Address": "Sanda-shi, Hyogo-ken, None, None, 6691339, None, Japan, 0795607855",
        "Description_of_Project": "NA_in_Archer",
        "Vendor_URL": "NA_in_Archer",
        "Vendor_Category": "NA_in_Archer",
        "Vendor_Contact": "NA_in_Archer",
        "Vendor_Contact_EmailID": "NA_in_Archer",
        "Business_Partner_EmailID": "NA_in_Archer",
        "Owner": "NA_in_Ariba",
        "BitsightScore": "730",
        "VendorURL": "NA_in_Snow",
        "Segment_Association": "NA_in_Snow",
        "Vendor_Account_Manager_ContactName": "NA_in_Snow",
        "Vendor_Account_ManagerEmail": "NA_in_Snow",
        "Vendor_ContactName": "NA_in_Snow",
        "VendorEmail": "NA_in_Snow",
        "Description_of_Services": "NA_in_Snow",
        "Category": "NA_in_Snow",
        "MarsCommercialContactEmails": "NA_in_Snow",
        "MarsCommercialContactName": "NA_in_Snow",
        "Technical_Contact_Name": "NA_in_Snow",
        "Technical_Contact_Email": "NA_in_Snow",
        "Segment": "NA_in_Snow",
        "Finished_Goods_Value_Close_Contracts": "0.0",
        "Finished_Goods_Value_Open_Contracts": "0.0",
        "SourceSystem": "SAP_APP",
        "Region": " ",
        "CountryName": "Japan",
        "Archer_VendorName": "NA",
        "Archer_SimilarityScore": "NA",
        "Ariba_VendorName": "NA_in_Ariba",
        "Ariba_SimilarityScore": "NA_in_Ariba",
        "Bitsight_VendorName": "NA_in_BitSight",
        "Bitsight_SimilarityScore": "NA_in_BitSight",
        "Snow_VendorName": "NA_in_Snow",
        "Snow_SimilarityScore": "NA_in_Snow",
        "VendorNameCombTranslation": "shinnippon calendar co.  ltd. peppy",
        "Year": 2024,
        "Period": 4
    }
]);
  const [isLoading, setisLoading] = useState(false);
  const componentRef_Print = useRef(null);

  const [VendorDetailsItems,SetVendorDetailsItems] = useState(props.dataValue);

  const FirstVendorName = VendorDetailsItems[0];
  const SecondVendorName = VendorDetailsItems[1]
  
  // const passtoken = JSON.parse(localStorage.getItem("okta-token-storage"))[
  //   "accessToken"
  // ]["accessToken"];

  const baseUrl = "/vrm/vendordetail?name=";
  const getUser_1 = encodeURIComponent(FirstVendorName);
  const getUser_2 = encodeURIComponent(SecondVendorName);

  const encodebaseURL_1 = `${baseUrl}${getUser_1}`;
  const encodebaseURL_2 = `${baseUrl}${getUser_2}`;

  // useEffect(() => {
  //   const fetchUsersdDetails = async () => {
  //     await fetch(`${encodebaseURL_1}`, {
  //       headers: {
  //         Authorization: `Bearer ${passtoken}`,
  //       },
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           response.json().then((json) => {
  //             setFirstVendorDetails(json);
  //             setisLoading(false)
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         setFirstVendorDetails(null);
  //         setisLoading(false)
  //         console.log(err)

  //       });
  //   };
  //   fetchUsersdDetails();
  // }, [encodebaseURL_1,passtoken]);

  // useEffect(() => {
  //   const fetchUsersdDetails_1 = async () => {
  //     await fetch(`${encodebaseURL_2}`, {
  //       headers: {
  //         Authorization: `Bearer ${passtoken}`,
  //       },
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           response.json().then((json) => {
  //             setSecondVendorDetails(json);
  //             setisLoading(false)
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         setSecondVendorDetails(null);
  //         setisLoading(false)
  //       });
  //   };
  //   fetchUsersdDetails_1();
  // }, [encodebaseURL_2,passtoken]);

  const PrintPopup = useReactToPrint({
    content: () => {
      const tableStat_1 = componentRef_Print.current.cloneNode(true);

      const PrintElem = document.createElement("div");
      const header = 
        `<img src="${Logo}" style="padding-bottom:2rem" alt="image" class="watermark"/>`;
      PrintElem.innerHTML = header;
    
      PrintElem.appendChild(tableStat_1);

      return PrintElem;
    },
  });

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function(match) {
      return match.toUpperCase();
    });
  }

  
  return (
    <div>
      <Popup
        trigger={
          <button style={{ padding: "inherit" }} className="button">
            <span
              style={{
                background: "#bb1ccc",
                color: "white",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              Compare
            </span>{" "}
          </button>
        }
        modal
        contentStyle={contentStyle}
      >
        {(close) => (
          <div style={{ display: "contents" }} className="modal">
            <div className="close" style={{ color: "white" }} onClick={close}>
              &times;
            </div>
            <div
              style={{ background: "#0000a0", color: "white" }}
              className="header"
            >
              Vendor Risk Management
{" "}
            </div>

            <div ref={componentRef_Print}>
              <div className="content">
                {/*<div className="Vname_PO">
                  <div style={{ fontSize: "18px", color: "#253978" }}>
                  Comparison between <span className='vendor_risk_items_cls'>{FirstVendorName}</span>  And <sapn className="vendor_risk_items_cls"> {SecondVendorName}</sapn>
                  </div>
                </div>*/}

                <table id="myTable_comparing">
                  <tbody>
                    <tr>
                      <th>
                        <span className="flexbody">
                          <span>Vendor Name</span>
                        </span>
                      </th>
                      {FirstVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "600" }} class="vendor_risk">
                            <Link
                              style={{
                                width: "100%",
                                background: "transparent",
                              }}
                              to={`/vendor?name=${item.VendorNameCombTranslation}`}
                            >
                              {capitalizeWords(item.VendorName)}
                            </Link>
                          </td>
                        );
                      })}
                      {SecondVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "600" }} class="vendor_risk">
                            <Link
                              style={{
                                width: "100%",
                                background: "transparent",
                              }}
                              to={`/vendor?name=${item.VendorNameCombTranslation}`}
                            >
                              {capitalizeWords(item.VendorName)}
                            </Link>
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th>
                        <span className="flexbody">
                          <span>Vendor ID</span>
                        </span>
                      </th>
                      {FirstVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                            >
                              <span class="vendor_risk_items_cls">
                                {item.VendorID}
                              </span>
                            </span>
                          </td>
                        );
                      })}
                      {SecondVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                            >
                              <span class="vendor_risk_items_cls">
                                {item.VendorID}
                              </span>
                            </span>
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th width="30%">
                        <span className="flexbody">
                          <span>Descriptions Of Services</span>
                        </span>
                      </th>
                      {FirstVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                            >
                              <span class="vendor_risk_items_cls">
                                {item.Description_of_Services}
                              </span>
                            </span>
                          </td>
                        );
                      })}
                      {SecondVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                            >
                              <span class="vendor_risk_items_cls">
                                {item.Description_of_Services}
                              </span>
                            </span>
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th>
                        <span className="flexbody">
                          <span>Active Contract</span>
                        </span>
                      </th>
                      {FirstVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                            >
                              <span class="vendor_risk_items_cls">
                                {item.Counts_Of_Open_Contracts}
                              </span>
                            </span>
                          </td>
                        );
                      })}
                      {SecondVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                            >
                              <span class="vendor_risk_items_cls">
                                {item.Counts_Of_Open_Contracts}
                              </span>
                            </span>
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th>
                        <span className="flexbody">
                          <span>Security Rating Score</span>
                        </span>
                      </th>
                      {FirstVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                            >
                              <span class="vendor_risk_items_cls">
                                {item.BitsightScore === "NA_in_BitSight"
                                  ? 0
                                  : item.BitsightScore}
                              </span>
                            </span>
                          </td>
                        );
                      })}
                      {SecondVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                            >
                              <span class="vendor_risk_items_cls">
                                {item.BitsightScore === "NA_in_BitSight"
                                  ? 0
                                  : item.BitsightScore}
                              </span>
                            </span>
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th>
                        <span className="flexbody">
                          <span>Inherent Risk</span>
                        </span>
                      </th>
                      {FirstVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                            >
                              {item.ISF_Inherent_Risk_Rating === "Low" ? (
                                <div class="vendor_risk_items_low vendor_loc itemsFlex">
                                  <span>{item.ISF_Inherent_Risk_Rating}</span>
                                </div>
                              ) : item.ISF_Inherent_Risk_Rating === "High" ? (
                                <div class="vendor_risk_items_high vendor_loc itemsFlex">
                                  <span>{item.ISF_Inherent_Risk_Rating}</span>
                                </div>
                              ) : item.ISF_Inherent_Risk_Rating ===
                                "Moderate" ? (
                                <div class="vendor_risk_items_modrate vendor_loc itemsFlex">
                                  <span>{item.ISF_Inherent_Risk_Rating}</span>
                                </div>
                              ) : item.ISF_Inherent_Risk_Rating ===
                                "Very Low" ? (
                                <div class="vendor_risk_items_veryLow vendor_loc itemsFlex">
                                  <span>{item.Rating}</span>
                                </div>
                              ) : (
                                <div class="vendor_risk_items_critical vendor_loc itemsFlex">
                                  <span>
                                    {item.ISF_Inherent_Risk_Rating ===
                                    "NA_in_Archer"
                                      ? "N/A"
                                      : item.ISF_Inherent_Risk_Rating}
                                  </span>
                                </div>
                              )}
                            </span>
                          </td>
                        );
                      })}
                      {SecondVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                            >
                              {item.ISF_Inherent_Risk_Rating === "Low" ? (
                                <div class="vendor_risk_items_low vendor_loc itemsFlex">
                                  <span>{item.ISF_Inherent_Risk_Rating}</span>
                                </div>
                              ) : item.ISF_Inherent_Risk_Rating === "High" ? (
                                <div class="vendor_risk_items_high vendor_loc itemsFlex">
                                  <span>{item.ISF_Inherent_Risk_Rating}</span>
                                </div>
                              ) : item.ISF_Inherent_Risk_Rating ===
                                "Moderate" ? (
                                <div class="vendor_risk_items_modrate vendor_loc itemsFlex">
                                  <span>{item.ISF_Inherent_Risk_Rating}</span>
                                </div>
                              ) : item.ISF_Inherent_Risk_Rating ===
                                "Very Low" ? (
                                <div class="vendor_risk_items_veryLow vendor_loc itemsFlex">
                                  <span>{item.Rating}</span>
                                </div>
                              ) : (
                                <div class="vendor_risk_items_critical vendor_loc itemsFlex">
                                  <span>
                                    {item.ISF_Inherent_Risk_Rating ===
                                    "NA_in_Archer"
                                      ? "N/A"
                                      : item.ISF_Inherent_Risk_Rating}
                                  </span>
                                </div>
                              )}
                            </span>
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th>
                        <span className="flexbody">
                          <span>Residual Risk</span>
                        </span>
                      </th>
                      {FirstVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                            >
                              {item.Risk_Rating === "Low" ? (
                                <div class="vendor_risk_items_low vendor_loc itemsFlex">
                                  <span>{item.Risk_Rating}</span>
                                </div>
                              ) : item.Risk_Rating === "High" ? (
                                <div class="vendor_risk_items_high vendor_loc itemsFlex">
                                  <span>{item.Risk_Rating}</span>
                                </div>
                              ) : item.Risk_Rating === "Moderate" ? (
                                <div class="vendor_risk_items_modrate vendor_loc itemsFlex">
                                  <span>{item.Risk_Rating}</span>
                                </div>
                              ) : item.Risk_Rating === "Very Low" ? (
                                <div class="vendor_risk_items_veryLow vendor_loc itemsFlex">
                                  <span>{item.Rating}</span>
                                </div>
                              ) : (
                                <div class="vendor_risk_items_critical vendor_loc itemsFlex">
                                  <span>
                                    {item.Risk_Rating === "NA_in_Archer"
                                      ? "N/A"
                                      : item.Risk_Rating}
                                  </span>
                                </div>
                              )}
                            </span>
                          </td>
                        );
                      })}
                      {SecondVendorDetails.map((item) => {
                        return (
                          <td style={{ fontWeight: "normal" }}>
                            <span
                              style={{
                                display: "grid",
                                justifyContent: "start",
                              }}
                            >
                              {item.Risk_Rating === "Low" ? (
                                <div class="vendor_risk_items_low vendor_loc itemsFlex">
                                  <span>{item.Risk_Rating}</span>
                                </div>
                              ) : item.Risk_Rating === "High" ? (
                                <div class="vendor_risk_items_high vendor_loc itemsFlex">
                                  <span>{item.Risk_Rating}</span>
                                </div>
                              ) : item.Risk_Rating === "Moderate" ? (
                                <div class="vendor_risk_items_modrate vendor_loc itemsFlex">
                                  <span>{item.Risk_Rating}</span>
                                </div>
                              ) : item.Risk_Rating === "Very Low" ? (
                                <div class="vendor_risk_items_veryLow vendor_loc itemsFlex">
                                  <span>{item.Rating}</span>
                                </div>
                              ) : (
                                <div class="vendor_risk_items_critical vendor_loc itemsFlex">
                                  <span>
                                    {item.Risk_Rating === "NA_in_Archer"
                                      ? "N/A"
                                      : item.Risk_Rating}
                                  </span>
                                </div>
                              )}
                            </span>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{ background: "#3d3d3d" }} className="actions">
              <button
                style={{ margin: "10px 15px" }}
                className="button"
                onClick={() => {
                  console.log("closed ");
                  close();
                }}
              >
                Close
              </button>

              <button
                style={{ margin: "10px 15px" }}
                className="button"
                onClick={() => {
                  console.log("Printing... ");
                  PrintPopup();
                }}
              >
                Print
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default Compareing
