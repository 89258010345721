import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";

ChartJS.register(CategoryScale, BarElement, LinearScale, Tooltip, Title);

const Vertical_BarCharts = () => {
  const chartRef = useRef(null);
  const [Barchart, setBarchart] = useState([
    {
        "2022": {
            "High": 4
        },
        "quarter": "P9"
    },
    {
        "2022": {
            "High": 2
        },
        "quarter": "P13"
    },
    {
        "2023": {
            "High": 2
        },
        "2024": {
            "High": 11
        },
        "quarter": "P2"
    },
    {
        "2023": {
            "High": 8
        },
        "2024": {
            "High": 12
        },
        "quarter": "P3"
    },
    {
        "2023": {
            "High": 9
        },
        "2024": {
            "High": 15
        },
        "quarter": "P4"
    },
    {
        "2023": {
            "High": 11
        },
        "quarter": "P7"
    },
    {
        "2023": {
            "High": 12
        },
        "quarter": "P8"
    },
    {
        "2023": {
            "High": 10
        },
        "quarter": "P10"
    },
    {
        "2023": {
            "High": 10
        },
        "quarter": "P11"
    },
    {
        "2023": {
            "High": 10
        },
        "quarter": "P12"
    },
    {
        "2024": {
            "High": 11
        },
        "quarter": "P1"
    }
]);
  const [isloading, setLoading] = useState(false);
  const [error, setError] = useState(null);
 

  //console.log(Barchart);

  // const DataItem = [
  //   {
  //     2022: {
  //       High: 4,
  //     },
  //     quarter: "P9",
  //   },
  //   {
  //     2022: {
  //       High: 2,
  //     },
  //     quarter: "P13",
  //   },
  //   {
  //     2023: {
  //       High: 8,
  //     },
  //     quarter: "P1",
  //   },
  // ];

  // const keysSet = new Set();
  // Barchart.forEach(item => {
  //   const keys = Object.keys(item)[0];
  //   keys.forEach(key => keysSet.add(key));
  // });

  // const keysArray = Array.from(keysSet);
  // keysArray.reverse();
  // //keysArray = keysArray.filter(e => e !== "quarter");

  // console.log("keysArray");
  // console.log(keysArray);

  //  const YearValue = ['2023','2022']

  const keysArray = Barchart.map((item) => Object.keys(item)[0]);
 // console.log(keysArray);
  keysArray.reverse();

  let uniqueArray = keysArray.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
 // console.log(uniqueArray);

  var data = {
    labels: [
      "P1",
      "P2",
      "P3",
      "P4",
      "P5",
      "P6",
      "P7",
      "P8",
      "P9",
      "P10",
      "P11",
      "P12",
      "P13",
    ],
    datasets: [
      {
        label: " High Risk Vendors",
        data: Barchart,
        backgroundColor: "#e3a9a9",
        borderColor: "#e3a9a9",
        borderWidth: 0,
        borderRadius: 10,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    parsing: {
      xAxisKey: "quarter",
      yAxisKey: `${uniqueArray[0]}.High`,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#253978",
          stepSize: 1,
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: false, // <-- this removes y-axis line
          lineWidth: 0.3,
        },
        ticks: {
          color: "#253978",
          stepSize: 1,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            // Customize the tooltip title
            const year = document.getElementById("year").value;
            return `${(context[0].label) === 'P1' ? 'Period 1' : (context[0].label) === 'P2' ? 'Period 2' : (context[0].label) === 'P3' ? 'Period 3' : (context[0].label) === 'P4' ? 'Period 4':(context[0].label) === 'P5' ? 'Period 5' : (context[0].label) === 'P6' ? 'Period 6' :(context[0].label) === 'P7' ? 'Period 7' : (context[0].label) === 'P8' ? 'Period 8' : (context[0].label) === 'P9' ? 'Period 9' : (context[0].label) === 'P10' ? 'Period 10':(context[0].label) === 'P11' ? 'Period 11' : (context[0].label) === 'P12' ? 'Period 12'  : 'Period 13 '} of ${year}`;
          },
          label: function (context) {
            let label = context.dataset.label || '';
           
            if (context.parsed.y !== null) {
            
              console.log(label)
              label += `: ${context.parsed.y}`;
            }
            return label;
          },
        },
      },
      title: {
        display: true,
        padding: {
          top: 0,
          bottom: 10,
        },
        font: {
          size: 20,
        },
      },
    },
    indexAxis: "x",
    legend: {
      display: false,
    },
  };

  const ChangeTheData = () => {
    const year = document.getElementById("year").value;
    options.parsing.yAxisKey = `${year}.High`;
    const chartItem = chartRef.current;
    chartItem.update();
  };

  /*
       const ChangeTheData = () =>{
        const year = document.getElementById('year').value;
        const risk = document.getElementById('risk').value;
        options.parsing.yAxisKey = `${year}.${risk}`;
        const chartItem = chartRef.current;
        chartItem.update()


      }
       <select style={{display:"none"}} className="SelectItems" id="risk" onChange={ChangeTheData}>
            <option value="High">High</option>
          </select>
      */

  return (
    <div>
      {isloading && <Skeleton baseColor="rgb(247, 249, 252)" height={400} />}

      {!isloading && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="GraphText"
          >
            <span>High Risk Vendors</span>
            <div style={{ gridGap: "10px" }}>
              <select
                className="SelectItems"
                id="year"
                onChange={ChangeTheData}
              >
                {uniqueArray.map((year) => {
                  return <option value={year}>{year}</option>;
                })}
              </select>
            </div>
          </div>

          <div style={{ height: "400px" }}>
            <Bar
              ref={chartRef}
              id="myChart"
              data={data}
              height={400}
              options={options}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Vertical_BarCharts;
