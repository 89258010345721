import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { FaSignOutAlt } from "react-icons/fa";
import Popup from "reactjs-popup";
import Waring from "../../img/warning.png";

const contentStyle = {
  maxWidth: "300px",
  width: "100%",
  background:"white",
  borderRadius:"20px"
};
// Basic component with logout button
const Logout = () => { 
  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`
    await oktaAuth.signOut();
  };

  return (
    <>
      

      <Popup
    trigger={<button style={{display:"contents"}} className="items_menu logoutCss">
       <div className="items_menu logoutCss">
        <div style={{display:"grid",justifyContent:"center",justifyItems:"center",gridGap:"7px"}}>
          <FaSignOutAlt fontSize={28} />
          <span className='Logout_text_cls' style={{fontSize:"12px",fontWeight:"600"}}>Logout</span>
        </div>
        <div className="item_menuText">
          Logout
        </div>
      </div>
    </button>}
    modal
    contentStyle={contentStyle}
  >
    {close => (
      <div style={{ display: "contents",height:"auto",textAlign:"center",fontSize:"20px",padding:"1rem" }} className="modal">
        <div style={{right: "4px",top: "7px",color:"white"}} className="close" onClick={close}>
          &times;
        </div>
        <div style={{backgroundColor:"#0000a0"}} className="header">
          <img width={64} src={Waring} alt="Warning"/>
          <div style={{textAlign:"center",color:"white",fontSize:"20px",fontWeight:"600",paddingTop:"5px"}}>WARNING</div>
          <div style={{height:"auto",textAlign:"center",fontSize:"16px",paddingTop:"10px",fontWeight:"normal",color:"white" }}>
          Do you want to logout?
        </div>
        </div>
        <div style={{background:"white"}} className="actions">
          

          <button style={{margin:"10px 15px",background: "#0000a0",border: "none",borderRadius: "2px",color: "white",paddingTop: "4px",paddingBottom: "4px"}}
            className="button"
            onClick={() => {
              console.log("Yes");
              logout();
            }}
          >
            Yes
          </button>

          <button style={{margin:"10px 15px",background: "#d1d3d5",border: "none",borderRadius: "2px",color: "white",paddingTop: "4px",paddingBottom: "4px"}}
            className="button"
            onClick={() => {
              console.log("No");
              close();
            }}
          >
            No
          </button>
        </div>
      </div>
    )}
  </Popup>
    </>
  );
};

export default Logout;