import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";
import {
  getElementAtEvent
} from 'react-chartjs-2';
import { useHistory } from "react-router-dom";

ChartJS.register(CategoryScale, BarElement, LinearScale, Tooltip, Title);

const Horizontal_Barcharts = (props) => {
  const [HorizontalBar, setHorizontalBar] = useState({
    "totalNofVendors": 40481,
    "vendorsByRiskCategories": {
        "High": 15,
        "Very Low": 2,
        "Moderate": 94,
        "NA_in_Archer": 40322,
        "Low": 45,
        "Critical": 1,
        "None": 2
    },
    "vendorCategoriesByPeriod": null,
    "vendorsBySegments": null,
    "difference": 3,
    "homeLastUpdate": "2024-03-31 00:00:00.0",
    "workflowLastUpdate": "2024-05-08 00:00:00.0"
});
  const [iserror, setError] = useState(null);
  const [isloading, setLoading] = useState(false);
  const chartRef = useRef(null)
  const history = useHistory();
 

  let optionsData = [
    {
      Dlabel: "Very Low",
      Risk: HorizontalBar?.vendorsByRiskCategories["Very Low"],
    },
    { Dlabel: "Low", Risk: HorizontalBar?.vendorsByRiskCategories.Low },
    {
      Dlabel: "Moderate",
      Risk: HorizontalBar?.vendorsByRiskCategories.Moderate,
    },
    { Dlabel: "High", Risk: HorizontalBar?.vendorsByRiskCategories.High },
    {
      Dlabel: "Critical",
      Risk: HorizontalBar?.vendorsByRiskCategories.Critical,
    },
  ];
 // console.log("My data labe is", optionsData);

 

 const checkData = () =>{
  optionsData && optionsData.map((item) =>{
    //console.log(item)
    if(item.Risk === null || item.Risk === '' || item.Risk === undefined){

      item.Dlabel= '';
      
    }
    return item.Risk
  })
 }
 checkData();

 const RemoveLable = () =>{
  let removeIndex = optionsData.map((item) => item.Dlabel).indexOf('');
 // console.log(removeIndex)
  ~removeIndex && optionsData.splice(removeIndex, 1);
 }
 RemoveLable();

  var data = {
    labels: optionsData.map((DataLabel) => DataLabel.Dlabel),
    datasets: [
      {
        label: " Total Risk Vendors is",
        data: optionsData.map((barD) => barD.Risk),
        backgroundColor: ["#8ee18e", "#00b610", "rgba(255,165,0,.2)", "#e3a9a9", "#ffcccb"],
        borderColor: ["#8ee18e", "#00b610", "rgba(255,165,0,.2)", "#e3a9a9", "#ffcccb"],
        borderWidth: 0,
        borderRadius: 10,
      },
    ],
  };

  const printElementAtEvent = (element) => {
    if (!element.length) return;
    const { datasetIndex, index } = element[0];
    console.log(data.labels[index], data.datasets[datasetIndex].data[index]); 
    //window.location.href='/vendorRisk'
    
    history.push(`/Category?RiskRating=${data.labels[index]}`)
  
  }


  var options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#253978",
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: false, // <-- this removes y-axis line
          lineWidth: 0.5,
        },
        ticks: {
          color: "#253978",
        },
      },
    },
    plugins: {
      tooltips: {
        enabled: true,
        mode: 'nearest',
        intersect: true,
        position: 'average',
        callbacks: {
          title: function (context) {
              // Customize the tooltip title
              const dataset = context[0].dataset;
              return `Vendors By Residual Risk Level : ${dataset.label}`;
            },
          label: function (context) {
            let label = context.dataset.label || '';   
            if (context.parsed.y !== null) {     
              // console.log(label)
              label += `: ${context.parsed.y}`;
            }
            return label;
          },
        },
      },
      title: {
        display: true,
        padding: {
          top: 0,
          bottom: 10,
        },
        font: {
          size: 20,
        },
      },
    },
    indexAxis: "y",
    legend: {
      display: true,
    },
  };

  return (
    <div>
      <div>
        {isloading && <Skeleton baseColor="rgb(247, 249, 252)" height={400} />}

        {!isloading && (
          <div>
            <div
              style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              className="GraphText"
            >
              <span>Vendors By Risk Level - {props.date}</span>
              <span style={{padding:"14px"}} className="negativeCssRisk">Residual Risk </span>
            </div>

            <div className="chart-container" style={{ height: "400px" }}>
              <Bar
                ref={chartRef}
                data={data}
                height={400}
                options={options}
                onClick={(event) => {
                  const element = printElementAtEvent(getElementAtEvent(chartRef.current, event));
                  console.log(element)
                  //const elements = getElementsAtEvent(chartRef.current, event);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Horizontal_Barcharts;
