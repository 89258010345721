import React from "react";
import { useState, useEffect } from "react";
import CardBoxs from "../reuseable-layout/CardBoxs";
import Report from "../layout/Report";
import NavigarionBar from "../nav/NavigarionBar";
import SideMenu from "../nav/SideMenu";
import Loading from "../spinner/Loading";
import styled from "styled-components";
import SearchBox from "../search/SearchBox";
import { Link } from "react-router-dom";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';


const HomePageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color:red;
`;

export default function Homepage() {
  const [Summrydata, setSummrydata] = useState({
    "totalNofVendors": 36481,
    "vendorsByRiskCategories": {
        "High": 15,
        "Very Low": 2,
        "Moderate": 94,
        "NA_in_Archer": 40322,
        "Low": 45,
        "Critical": 1,
        "None": 2
    },
    "vendorCategoriesByPeriod": null,
    "vendorsBySegments": null,
    "difference": 3,
    "homeLastUpdate": "2024-03-31 00:00:00.0",
    "workflowLastUpdate": "2024-05-08 00:00:00.0"
});
  const [isError, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

 
  useEffect(() => {
    document.title = "VCRM INSIGHT - HOME";
  });


  const getDateTimeInfo = (dateStr) => {
    const dateObj = new Date(dateStr);
  
    // Get the month name
    const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(dateObj);
  
    // Get the year
    const year = dateObj.getFullYear();
  
    // Get the number of days in the month
    const numberOfDays = new Date(year, dateObj.getMonth() + 1, 0).getDate();
  
    // Get the day of the week
    const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(dateObj);

    // Get the day with ordinal suffix
    const day = dateObj.getDate();
    const dayWithSuffix = addOrdinalSuffix(day);

    // Get the time
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
  
    return {
      monthName,
      year,
      numberOfDays,
      dayOfWeek,
      dayWithSuffix,
      hours,
      minutes,
      seconds,
      ampm
    };
  };

  const addOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const remainder = number % 100;
    const suffix = suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0];
    return `${number}${suffix}`;
  };

 const HighPerscentage = Summrydata.difference;


  return (
    <div>
      <NavigarionBar />
      <SideMenu />
      <div>
        <div id="main">
         
          {isLoading && (
            <HomePageWrapper>
              <div className="PlaseWait">
                <div className="spinner_class">
                  <Loading />
                </div>
                <div>Please wait sometime</div>
              </div>
            </HomePageWrapper>
          )}

          {isError && (
            <HomePageWrapper>
              <ErrorWrapper>
              <div className="PlaseWait">
                <div className="spinner_class">
                {`There is a problem fetching the post data - ${isError}`}
                </div>
                <div>Please try after sometime</div>
              </div>
              </ErrorWrapper>
            </HomePageWrapper>
          )}

          {!isLoading && Summrydata.length !== 0 && (
            <>
              <div className="searchBarSections">
                <h1 className="vender_Risk_Management_System text_header_color">
                  Vendor Risk Management

                </h1>
                <div className="headeritemssections">
                  <div id="searchBar" className="Search_section">
                    <div id="Seachbarid_" className="SearchBarClass">
                      <SearchBox/>   
                    </div>
                  </div>
                </div>
              </div>

              <div className="CardSections">
                <div>
                  <div className="items_card_box">
                    <div style={{display:"flex",marginTop:"4rem",justifyContent:"space-between"}} className="CardSections_1">
                     
                      <div className="visuals_container">
                        
                        <div style={{width:"99%"}}>
                          <Link to={`/SegmentsCatagory`}>
                            <CardBoxs
                              numbers={Summrydata.totalNofVendors}
                              title = 'No of Vendors'
                              text="SAP"
                              id="SummrydataId"
                              iconsData= { <TrendingUpIcon  style={{visibility:"hidden"}}/>}
                              className="paddingStyle"
                              LastUpdate={getDateTimeInfo(Summrydata.homeLastUpdate).dayWithSuffix +", " + getDateTimeInfo(Summrydata.homeLastUpdate).monthName +", "+getDateTimeInfo(Summrydata.homeLastUpdate).year + " "+ getDateTimeInfo(Summrydata.homeLastUpdate).hours + "" +getDateTimeInfo(Summrydata.homeLastUpdate).ampm}

                            />
                          </Link>
                          
                        </div>

                        <div style={{width:"99%"}}>
                          <Link to={`/Category?RiskRating=High`}>
                          <CardBoxs
                            title="High Risk Vendors"
                            text="Periodically"
                            classname={`${(HighPerscentage > 0) ? "negativeCss" : "SinceLastWeekNew"}`}
                            numbers={Summrydata?.vendorsByRiskCategories.High}
                            Diffrence={(HighPerscentage > 0) ? `+${HighPerscentage}` : HighPerscentage}
                            iconsData= {(HighPerscentage > 0) ? <TrendingUpIcon/> : <TrendingDownIcon/>}
                            classnameIcon = {`${(HighPerscentage > 0) ? "negativeCssIcons" : "SinceLastWeekicons"}`}
                            id="HighRiskVendorsId"
                            LastUpdate={getDateTimeInfo(Summrydata.homeLastUpdate).dayWithSuffix +", " + getDateTimeInfo(Summrydata.homeLastUpdate).monthName +", "+getDateTimeInfo(Summrydata.homeLastUpdate).year + " "+ getDateTimeInfo(Summrydata.homeLastUpdate).hours + "" +getDateTimeInfo(Summrydata.homeLastUpdate).ampm}

                          />
                          </Link>          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="PBIReport">
                <Report year={getDateTimeInfo(Summrydata.homeLastUpdate).monthName + ", " + getDateTimeInfo(Summrydata.homeLastUpdate).year}/>
              </div>
            </>
          )}

        </div>
      </div>
    </div>
  );
}
