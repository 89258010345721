import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Logo from "../../img/Aays.png";
import { FaBars, FaUserCircle } from "react-icons/fa";
import $ from "jquery";
import { Link } from "react-router-dom";
import NotificationDrawer from '../notifications/NotificationDrawer';
import axios from 'axios';

export default function NavigarionBar(props) {

  const { authState, oktaAuth } = useOktaAuth();

  const [userInfo, setUserInfo] = useState(null);
  const [openNotification, setopenNotification] = useState(false);
  const [userNotifications, setuserNotifications] = useState([
    
      {
          "notificationId": "727",
          "userEmail": "nabasundarr@aaysinsight.com",
          "vendorName": "aeb",
          "previousStatus": "NA_in_Archer",
          "currentStatus": "Moderate",
          "status": "Unseen",
          "changeTime": "2024-03-31 13:44:06.42"
      },
      {
          "notificationId": "724",
          "userEmail": "nabasundarr@aaysinsight.com",
          "vendorName": "amcor",
          "previousStatus": "NA_in_Archer",
          "currentStatus": "Moderate",
          "status": "Unseen",
          "changeTime": "2024-03-31 13:44:06.42"
      },
      {
          "notificationId": "721",
          "userEmail": "nabasundarr@aaysinsight.com",
          "vendorName": "ava genomics consultancy aps",
          "previousStatus": "NA_in_Archer",
          "currentStatus": "Low",
          "status": "Unseen",
          "changeTime": "2024-03-31 13:44:06.42"
      },
      {
          "notificationId": "722",
          "userEmail": "nabasundarr@aaysinsight.com",
          "vendorName": "b2b techno(Loiret)",
          "previousStatus": "NA_in_Archer",
          "currentStatus": "High",
          "status": "Unseen",
          "changeTime": "2024-03-31 13:44:06.42"
      },
      {
          "notificationId": "729",
          "userEmail": "nabasundarr@aaysinsight.com",
          "vendorName": "bpx s.a.",
          "previousStatus": "NA_in_Archer",
          "currentStatus": "High",
          "status": "Unseen",
          "changeTime": "2024-03-31 13:44:06.42"
      },
      {
          "notificationId": "728",
          "userEmail": "nabasundarr@aaysinsight.com",
          "vendorName": "consumer panel poland gfk sp.z.o.o",
          "previousStatus": "NA_in_Archer",
          "currentStatus": "Moderate",
          "status": "Unseen",
          "changeTime": "2024-03-31 13:44:06.42"
      },
      {
          "notificationId": "725",
          "userEmail": "nabasundarr@aaysinsight.com",
          "vendorName": "ecovadis inc(New York)",
          "previousStatus": "NA_in_Archer",
          "currentStatus": "Moderate",
          "status": "Unseen",
          "changeTime": "2024-03-31 13:44:06.42"
      },
      {
          "notificationId": "720",
          "userEmail": "nabasundarr@aaysinsight.com",
          "vendorName": "infor us inc(Georgia)",
          "previousStatus": "NA_in_Archer",
          "currentStatus": "Moderate",
          "status": "Unseen",
          "changeTime": "2024-03-31 13:44:06.42"
      },
      {
          "notificationId": "719",
          "userEmail": "nabasundarr@aaysinsight.com",
          "vendorName": "lingaro sp. z o.o.",
          "previousStatus": "NA_in_Archer",
          "currentStatus": "Low",
          "status": "Unseen",
          "changeTime": "2024-03-31 13:44:06.42"
      },
      {
          "notificationId": "723",
          "userEmail": "nabasundarr@aaysinsight.com",
          "vendorName": "rsm us llp",
          "previousStatus": "NA_in_Archer",
          "currentStatus": "Low",
          "status": "Unseen",
          "changeTime": "2024-03-31 13:44:06.42"
      },
      {
          "notificationId": "726",
          "userEmail": "nabasundarr@aaysinsight.com",
          "vendorName": "wipro limited(Karnataka)",
          "previousStatus": "NA_in_Archer",
          "currentStatus": "Moderate",
          "status": "Unseen",
          "changeTime": "2024-03-31 13:44:06.42"
      }
  
]);
  const [getNotificationsBatch, SetNotificationsBatch] = useState(13)


  const passtoken = JSON.parse(localStorage.getItem("okta-token-storage"))[
    "accessToken"
  ]["accessToken"];

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      setUserInfo(authState.idToken.claims);
      // SaveNotifications()
      // getUserNotifications()
    }
  }, [authState, oktaAuth]); // Update if authState changes


  // const GETnotiURL='/vrm/getVendorStatusChange'
  // useEffect(() => {
  //   const getNotifications = async () => {
  //     await fetch(`${GETnotiURL}`, {
  //       headers: {
  //         Authorization: `Bearer ${passtoken}`,
  //       },
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           response.json().then((json) => {
  //             console.log("fetch notifications successfully")
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //   };
   
  //   getNotifications();

  // }, [GETnotiURL,passtoken]);


  const menubar = () => {
    var sideBar = document.getElementById("mySidenav").style.width;
    if (sideBar === "") {
      document.getElementById("mySidenav").style.width = "300px";
      document.getElementById("main").style.marginLeft = "305px";
      $(".item_menuText").show();
      $('.Logout_text_cls').hide();
      $(".sideNavMenu_grid").css("justify-content", "initial");
      $(".sideNavMenu_grid").css("justify-items", "initial");
      $(".items_menu").css("padding","12px")

    } else if (sideBar === "80px") {
      document.getElementById("mySidenav").style.width = "300px";
      document.getElementById("main").style.marginLeft = "305px";
      $(".item_menuText").show();
      $('.Logout_text_cls').hide();
      $(".sideNavMenu_grid").css("justify-content", "initial");
      $(".sideNavMenu_grid").css("justify-items", "initial");
      $(".items_menu").css("padding","12px")


    } else {
      document.getElementById("mySidenav").style.width = "80px";
      document.getElementById("main").style.marginLeft = "85px";
      $(".item_menuText").hide();
      $('.Logout_text_cls').show();
      $(".sideNavMenu_grid").css("justify-content", "center");
      $(".sideNavMenu_grid").css("justify-items", "center");
      $(".items_menu").css("padding","6px")
      $("#HomePage").css("padding-left","15px")
      $("#HomePage").css("padding-right","15px")






    }
  };

  const emailid  = localStorage.getItem("myemail");


  // const SaveNotifications = ()=>{
  //   axios({
  //     method: 'post',
  //     url: `${process.env.REACT_APP_BASE_URL}/vrm/saveUserNotifications?userEmail=${emailid}`,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       "x-api-key" : process.env.REACT_APP_API_KEY,
  //     }
  //   })
  //   .then((response) => {
  //     // Handle successful response
  //     return response.data
         
  //   })
  //   .catch((error) => {
  //     // Handle error
  //     console.error(error);
  //   });
  // }

  // const getUsernotificationsUrl = `/vrm/getUserNotifications`;

  // const getUserNotifications = async () => {
  //   await fetch(`${getUsernotificationsUrl}?userEmail=${emailid}`, {
  //     headers: {
  //       Authorization: `Bearer ${passtoken}`,
  //     },
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         response.json().then((json) => {
  //         //  console.log(json)
  //           setuserNotifications(json)
  //           const badge = json;
  //           const unseenItems = json.filter((items) => items.status ==="Unseen")
  //           // console.log("unseenItems")
  //           // console.log(unseenItems)

  //          // console.log(badge.length)
  //           SetNotificationsBatch(unseenItems.length)
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setuserNotifications([])
  //     })
  // };


  return (
    <div>
      <nav className="NavBar common_bg_color">
        <div className="nav_header ">
          <div className="nav_header_item_div">
            <div className="nav_header_item">
              <div className="logoImg">
                <FaBars onClick={menubar} fontSize={24} cursor="pointer" />
                <Link to={"/"}>
                  <img src={Logo} width={130} alt="logo" />
                </Link>
              </div>
              <div style={{gridGap:"2rem"}} className="_navItems">
                <div className="item_bar" style={{cursor:"pointer"}}>
                  <NotificationDrawer NotificationsBatch={getNotificationsBatch} notifiactionData={userNotifications} buttonclick={()=>{setopenNotification(!openNotification)}}/>
                </div>

                <div className="navItems">
                  <div className="item_bar profileDetails">
                    <span>{userInfo?.name}</span>
                    <span>{userInfo?.email}</span>
                  </div>
                  <FaUserCircle size={40}/>
                </div>

              </div>
            </div>
          </div>
        </div>
      </nav>

     
    </div>
  );
}
