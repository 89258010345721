import React, { useEffect, useState } from "react";
import Drawer from '@mui/material/Drawer';

import { Avatar, Badge } from '@mui/material';
import NotificationsIcon from "@mui/icons-material/Notifications";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import axios from "axios";
import { Link } from 'react-router-dom';

export default function NotificationDrawer(props) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const [notificationsData, setnotificationsData] = useState(props.notifiactionData);
  const notificationsData = props.notifiactionData
  const passtoken = JSON.parse(localStorage.getItem("okta-token-storage"))[
    "accessToken"
  ]["accessToken"];
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function(match) {
      return match.toUpperCase();
    });
  }

  const updateNotifications = async (id) =>{
    await axios.patch(`/vrm/changeNotificationSeenStatus?notificationId=${id}`,{
          headers: {
             Authorization: `Bearer ${passtoken}`,
        }
     })

    .then((response) => {
      // Handle successful response
      console.log(response.data);
     
     
    })
    .catch((error) => {
      // Handle error
      console.error(error);
    });
  }

// const baseUrl = 'http://localhost:8082/notifications'
// useEffect(() => {

//   const fetchNotifications = async () => {
//     await fetch(`${baseUrl}`)
//       .then((response) => {
//         if (response.ok) {
//           response.json().then((json) => {
//             setnotificationsData(json);
//             setError(false);
//             setLoading(false);
//           });
//         }
//       })
//       .catch((err) => {
//         setError(true);
//         console.log(err.message);
//         setnotificationsData(null);
//       });
//   };
//   fetchNotifications();
//   const interval = setInterval(() => fetchNotifications(), 20000)
//   return () => {
//     clearInterval(interval);
//   }


// }, [baseUrl]);

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div
            style={{ cursor: "pointer" }}
            onClick={toggleDrawer(anchor, props.buttonclick)}
          >
            <Badge
              style={{ marginRight: "2rem" }}
              badgeContent={props.NotificationsBatch}
              color="primary"
            >
              <NotificationsIcon sx={{ color: "white" }} />
            </Badge>
          </div>

          <Drawer
            className="notificationDrawer"
            sx={{ boxShadow: "none", backgroundColor: "transparent" }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            overlayStyle={{ backgroundColor: "transparent" }}
          >
            <div className="sticky">Notifications</div>

            <div style={{ paddingTop: "0rem",height:"100%" }}>
              {isLoading && (
                <div>Please wait</div>
              )}

              {!isLoading && notificationsData.length !==0 &&(
                <div>
                  {notificationsData.map((items) => {
                    return (
                      <div style={{ color: "#000" }} className={(items.status ==="Unseen")? 'notificationUnseen':"notificationRead"}>
                        <div className="notificationsItems">
                          <div
                            style={{
                              display: "flex",
                              gridGap: "30px",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <Avatar
                              sx={{
                                background: "rgb(220, 233, 249)",
                                color: "#253978",
                              }}
                              alt="Remy Sharp"
                            >
                              {(items.vendorName.charAt(0)).toUpperCase()}
                            </Avatar>

                            <div style={{ display: "grid", gridGap: "3px" }}>
                              <span className="vendorNameCls">
                              <a  href={`/vendor` + `?name=${items.vendorName}`
                                            }>
                                  <span onClick={(e) => updateNotifications(items.notificationId)}>{capitalizeWords(items.vendorName)}</span>
                              </a>
                        
                                
                                
                              </span>
                              <span className="warrning">
                                Warning:{" "}
                                <span className="RiskDown">
                                  <span
                                    className={
                                      items.previousStatus === "Low" || items.previousStatus === "Very Low" ? "low" : "high"}
                                  >
                                    {items.previousStatus}
                                  </span>{" "}
                                  to{" "}
                                  <span
                                    className={
                                      items.currentStatus === "High" ? "high" : "low"
                                    }
                                  >
                                    {items.currentStatus}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>

                          <span
                            className={
                              items.currentStatus === "High"
                                ? "iconsNoti"
                                : "iconsNotiLow"
                            }
                          >
                            {items.currentStatus === "High" ? (
                              <TrendingUpIcon sx={{ fontSize: "28px" }} />
                            ) : (
                              <TrendingDownOutlinedIcon
                                sx={{ fontSize: "28px" }}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {!isLoading && notificationsData.length === 0 &&(
                <div className="NoNotifications">
                      <div>No notifications available</div>
                </div>
              )}
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}