import React, { useState } from "react";
import { FaInfoCircle, FaPlus, FaMinus } from "react-icons/fa";
import $ from 'jquery';

const BasicInformations = (props) => {
  const [TogglePlusIons, setTogglePlusIons] = useState(true);
  const CollapseButton = () => {
    setTogglePlusIons((prev) => !prev)
    $("#myTable").slideToggle(0);
  };

  return (
    <div>
      <div id="BasicInfo_id" className="basic_info">
        <div className="Basic_Informations" style={{display:"flex",justifyContent:"space-between",alignItems:"center",alignContent:"center"}}>
          <div>{props.title}</div>
          <div className="toggleIonsPlusicons" style={{cursor:"pointer",paddingRight:'10px'}} onClick={CollapseButton}>
            {TogglePlusIons ? <FaMinus color="#c1c1c1" fontSize={20} /> : <FaPlus color="#c1c1c1" fontSize={20}/>}
            </div>
        </div>
        <br />
        <table id="myTable">
          <tbody>
            <tr>
              <th>
                <span className="flexbody">
                  <span>Name</span>
                  <span className="grid_body">
                    <FaInfoCircle className="infoCircleico" fontSize={18} />
                    <span className="tooltiptext">SAP</span>
                  </span>
                </span>
              </th>
              <td>{props.Owner}</td>
            </tr>

            <tr>
              <th>
                <span className="flexbody">
                  <span>Address</span>
                  <span className="grid_body">
                    <FaInfoCircle className="infoCircleico" fontSize={18} />
                    <span className="tooltiptext">SAP</span>
                  </span>
                </span>
              </th>
              <td>{props.Address}</td>
            </tr>

            <tr>
              <th>
                <span className="flexbody">
                  <span> Vendor Url</span>
                  <span className="grid_body">
                    <FaInfoCircle className="infoCircleico" fontSize={18} />
                    <span className="tooltiptext">Archer</span>
                  </span>
                </span>
              </th>
              <td>
                <a target="_blank" rel="noreferrer" href={props.Vendor_URL}>{props.Vendor_URL}</a>
              </td>
            </tr>

            <tr>
              <th>
                <span className="flexbody">
                  <span>SAP ID Number</span>
                  <span className="grid_body">
                    <FaInfoCircle className="infoCircleico" fontSize={18} />
                    <span className="tooltiptext">SAP</span>
                  </span>
                </span>
              </th>
              <td>{props.VendorID}</td>
            </tr>

            <tr>
              <th>
                <span className="flexbody">
                  <span>Vendor Catagory</span>
                  <span className="grid_body">
                    <FaInfoCircle className="infoCircleico" fontSize={18} />
                    <span className="tooltiptext">Archer</span>
                  </span>
                </span>
              </th>
              <td>{props.CommericalCategory}</td>
            </tr>
          </tbody>
        </table>
        {TogglePlusIons ? <span></span> : <span>Please click the right ( + ) icons for view the {props.title}</span>}
      </div>
    </div>
  );
};

export default BasicInformations;
