import React, { useState, useEffect, useRef } from "react";
import { FaArrowDown, FaArrowUp, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import NavigarionBar from "../nav/NavigarionBar";
import SideMenu from "../nav/SideMenu";
import Loading from "../spinner/Loading";
import noDataAvailable from "../../img/No_Data_Available.jpg";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { useReactToPrint } from "react-to-print";
import Logo from "../../img/WaterMark.png";
import { FaPrint } from "react-icons/fa";
import Compareing from "../layout/Compareing";
import Skeleton from "react-loading-skeleton";

const RiskCatagory = () => {
  const [error, setError] = useState(false);
  const [iserror, setisError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isloading, setloading] = useState(false);
  const [items, setItems] = useState([
    {
        "VendorName": "group m",
        "VendorID": "15190887",
        "Rating": "High",
        "BitSightScore": "670.0",
        "Region": " ",
        "Country": "Japan",
        "VendorNameRegion": "group m",
        "InherentRiskRating": "High",
        "vnct": "group m"
    },
    {
        "VendorName": "bpx s.a.",
        "VendorID": "15192036",
        "Rating": "High",
        "BitSightScore": "730.0",
        "Region": " ",
        "Country": "Poland",
        "VendorNameRegion": "bpx s.a.",
        "InherentRiskRating": "High",
        "vnct": "bpx s.a."
    },
    {
        "VendorName": "actemium",
        "VendorID": "15051496",
        "Rating": "High",
        "BitSightScore": "NA_in_BitSight",
        "Region": " ",
        "Country": "Netherlands",
        "VendorNameRegion": "actemium",
        "InherentRiskRating": "Critical",
        "vnct": "actemium"
    },
    {
        "VendorName": "bull marketing sas",
        "VendorID": "15213209",
        "Rating": "High",
        "BitSightScore": "760.0",
        "Region": "BOGOTA",
        "Country": "Colombia",
        "VendorNameRegion": "bull marketing sas(BOGOTA)",
        "InherentRiskRating": "High",
        "vnct": "bull marketing sas(BOGOTA)"
    },
    {
        "VendorName": "b2b techno",
        "VendorID": "15032411",
        "Rating": "High",
        "BitSightScore": "720.0",
        "Region": "Loiret",
        "Country": "France",
        "VendorNameRegion": "b2b techno(Loiret)",
        "InherentRiskRating": "High",
        "vnct": "b2b techno(Loiret)"
    },
    {
        "VendorName": "interstates  inc.",
        "VendorID": "15016356",
        "Rating": "High",
        "BitSightScore": "680.0",
        "Region": "Iowa",
        "Country": "United States",
        "VendorNameRegion": "interstates  inc.(Iowa)",
        "InherentRiskRating": "Critical",
        "vnct": "interstates  inc.(Iowa)"
    },
    {
        "VendorName": "interstates inc",
        "VendorID": "15124211",
        "Rating": "High",
        "BitSightScore": "680.0",
        "Region": "Missouri",
        "Country": "United States",
        "VendorNameRegion": "interstates inc(Missouri)",
        "InherentRiskRating": "Critical",
        "vnct": "interstates inc(Missouri)"
    },
    {
        "VendorName": "paygos inc",
        "VendorID": "15145902",
        "Rating": "High",
        "BitSightScore": "760.0",
        "Region": "Ontario",
        "Country": "Canada",
        "VendorNameRegion": "paygos inc(Ontario)",
        "InherentRiskRating": "High",
        "vnct": "paygos inc(Ontario)"
    },
    {
        "VendorName": "agerpoint inc",
        "VendorID": "15210640",
        "Rating": "High",
        "BitSightScore": "780.0",
        "Region": "Florida",
        "Country": "United States",
        "VendorNameRegion": "agerpoint inc(Florida)",
        "InherentRiskRating": "High",
        "vnct": "agerpoint inc(Florida)"
    },
    {
        "VendorName": "koolteck systems sa de cv",
        "VendorID": "15195282",
        "Rating": "High",
        "BitSightScore": "NA_in_BitSight",
        "Region": "Mexico City",
        "Country": "Mexico",
        "VendorNameRegion": "koolteck systems sa de cv(Mexico City)",
        "InherentRiskRating": "High",
        "vnct": "koolteck systems sa de cv(Mexico City)"
    },
    {
        "VendorName": "switchpitch  llc",
        "VendorID": "17615981",
        "Rating": "High",
        "BitSightScore": "780.0",
        "Region": "District of Columbia",
        "Country": "United States",
        "VendorNameRegion": "switchpitch  llc(District of Columbia)",
        "InherentRiskRating": "High",
        "vnct": "switchpitch  llc(District of Columbia)"
    },
    {
        "VendorName": "pensa systems inc",
        "VendorID": "17585881",
        "Rating": "High",
        "BitSightScore": "760.0",
        "Region": "Texas",
        "Country": "United States",
        "VendorNameRegion": "pensa systems inc(Texas)",
        "InherentRiskRating": "High",
        "vnct": "pensa systems inc(Texas)"
    },
    {
        "VendorName": "workboard inc",
        "VendorID": "17604492",
        "Rating": "High",
        "BitSightScore": "770.0",
        "Region": "California",
        "Country": "United States",
        "VendorNameRegion": "workboard inc(California)",
        "InherentRiskRating": "Critical",
        "vnct": "workboard inc(California)"
    },
    {
        "VendorName": "woolman oy",
        "VendorID": "17613163",
        "Rating": "High",
        "BitSightScore": "770.0",
        "Region": " ",
        "Country": "Finland",
        "VendorNameRegion": "woolman oy",
        "InherentRiskRating": "High",
        "vnct": "woolman oy"
    },
    {
        "VendorName": "tallships solutions pty ltd",
        "VendorID": "10007715",
        "Rating": "High",
        "BitSightScore": "730.0",
        "Region": "New South Wales",
        "Country": "Australia",
        "VendorNameRegion": "tallships solutions pty ltd(New South Wales)",
        "InherentRiskRating": "Critical",
        "vnct": "tallships solutions pty ltd(New South Wales)"
    }
]);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("");
  const [filterRisk, setFilterRiks] = useState("");
  const [filterCountry, setfilterCountry] = useState("");
  const [filterInherent, setfilterInherent] = useState("");
  const [filterBsore, setfilterBscore] = useState("");

  const [filterCountryToggle, setfilterCountryToggle] = useState(false);
  const [currentPagelimit, setPageLimit] = useState(20);
  const componentRef_1 = useRef(null);
  const [alertMessage, setAlertMessage] = useState(false);
  const [Limit, setLimit] = useState(1);
  const [CompareingVendor, setCompareingVendor] = useState([]);
  const ref = useRef([]);

  const [isloadingTable, setloadingTable] = useState(false);

  // const passtoken = JSON.parse(localStorage.getItem("okta-token-storage"))[
  //   "accessToken"
  // ]["accessToken"];

  const url = new URL(window.location.href);

  const Risk = url.searchParams.get("RiskRating");
  // const baseUrl = "/vrm/search/vendors?category=";

  useEffect(() => {
    document.title = `VCRM INSIGHT - ${Risk.toUpperCase()} CATEGORY`;

    // const fetchSearchByname = async () => {
    //   setloadingTable(true);
    //   await fetch(
    //     `${baseUrl}${
    //       Risk === "High" ||
    //       Risk === "Low" ||
    //       Risk === "Critical" ||
    //       Risk === "Moderate" ||
    //       Risk === "Very Low"
    //         ? Risk
    //         : "none"
    //     }`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${passtoken}`,
    //       },
    //     }
    //   )
    //     .then((response) => {
    //       if (response.ok) {
    //         response.json().then((json) => {
    //           setItems(json);
    //           setError(false);
    //           setloading(false);
    //           setloadingTable(false);


    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       setError(true);
    //       setisError(err.message);
    //       setItems(null);
    //       setloading(false);
    //       setloadingTable(false);

    //     });
    // };

    // fetchSearchByname();
  }, []);

  const filterNatoDisplay = () => {
    items &&
      items.map((dataNa) => {
        if (dataNa.Region === " ") {
          dataNa.Region = "N/A";
        }
      });
  };

  filterNatoDisplay();

  const data = Object.values(items);
  const search_parameters = Object.keys(Object.assign({}, ...data));
  const filter_items = [...new Set(data.map((item) => item.Region))];
  const filter_Country = [...new Set(data.map((item) => item.Country))];
  const filter_inher= [...new Set(data.map((item) => item.InherentRiskRating))];
  const filter_Bitsight_1= [...new Set(data.map((item) => item.BitSightScore))];
  
  const strAscending = filter_Country.sort();
  const strAscending_Region = filter_items.sort();
  const filter_Bitsight = filter_Bitsight_1.sort();

  // function isBigEnough(value) {
  //   return value >= Number(filterBsore)
  // }

  //const myarrydata = items.filter(isBigEnough);
  //console.log(myarrydata)

  //const myarrydata1 = filter_Bitsight.filter(isBigEnough);
  //console.log(myarrydata1)

  let filteredData = [];

  filteredData = items.filter((obj) => {
    return obj;
  });

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  function search(filteredData) {
    return filteredData.filter(
      (item) =>
        item.Country.includes(filterCountry) &&
        item.Region.includes(filter) &&
        item.Rating.includes(filterRisk) &&
        item.InherentRiskRating.includes(filterInherent) &&
        item.BitSightScore.includes(filterBsore) &&
        search_parameters.some(
          (parameter) =>
            item[parameter].toString().toLowerCase().includes(query.toLowerCase()) 
        )
    );
  }

  function filter_items_by_region_data(filteredData) {
    return filteredData.filter((item) => item.Country.includes(filterCountry));
  }

  const resetAll = (e) => {
    e.preventDefault();
    setfilterInherent("");
    setfilterBscore("")
    setFilter("");
    setfilterCountry("");
    setQuery("");
    setCurrentPage(1);
    setfilterCountryToggle(false);
    Unchecked();
  };

  const Unchecked = () => {

    // console.log(ref.current.length)
     for (let i = 0; i < ref.current.length; i++) {
         ref.current[i].checked = false;
         setLimit(1);
         setCompareingVendor([])
 
     }
 
 }

  // function RemoveDataFromCheckbox() {
  //   var x = document.getElementsByClassName("checkbox");
  //   for (let i = 0; i <= x.length; i++) {
  //     x[i].checked = false;
  //     setLimit(1);
  //     setCompareingVendor([]);
  //   }
  // }

  const filter_items_by_region = [
    ...new Set(
      filter_items_by_region_data(filteredData).map((item) => item.Region)
    ),
  ];

  const filter_items_by_region_sort = filter_items_by_region.sort();

  const filterByundefinedData = () => {
    setFilter("");
    setFilterRiks("");
  };

  let NUM_OF_RECORDS = search(filteredData).length;
  if (NUM_OF_RECORDS <= currentPagelimit) {
    $(".pagination").hide(true);
  } else {
    $(".pagination").show(true);
  }

  const currentData = search(filteredData).slice(
    (currentPage - 1) * currentPagelimit,
    (currentPage - 1) * currentPagelimit + currentPagelimit
  );

  const pageCount = Math.ceil(search(filteredData).length / currentPagelimit);

  const handelepageclick = (data) => {
    console.log(data.selected);
    setCurrentPage(data.selected + 1);
  };

  const printSegment = useReactToPrint({
    content: () => {
      const tableStat_1 = componentRef_1.current.cloneNode(true);

      const PrintElem = document.createElement("div");
      const header = `<img src="${Logo}" style="padding-bottom:2rem" alt="image" class="watermark"/>`;
      PrintElem.innerHTML = header;

      PrintElem.appendChild(tableStat_1);

      return PrintElem;
    },
  });

  const handleCheck = (event) => {
    if (event.target.checked) {
      if (Limit > 2) {
        console.log("Please Select 2 checkbox only");
        setAlertMessage(true);
        setTimeout(() => {
          setAlertMessage(false);
        }, 2000);
        event.target.checked = false;
        return false;
      } else {
        setLimit(Limit + 1);
        setCompareingVendor([...CompareingVendor, event.target.value]);
      }
    }
    if (event.target.checked === false) {
      setLimit(Limit - 1);
      var index = CompareingVendor.indexOf(event.target.value);
      setCompareingVendor([
        ...CompareingVendor.slice(0, index),
        ...CompareingVendor.slice(index + 1),
      ]);
    }
  };

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function(match) {
      return match.toUpperCase();
    });
  }

  return (
    <div>
      <NavigarionBar />
      <SideMenu />
      <div id="main">
        {isloading && (
          <div
            style={{
              height: "100%",
              display: "grid",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div className="PlaseWait">
              <div className="spinner_class">
                <Loading />
              </div>
              <div>Please wait sometime</div>
            </div>
          </div>
        )}

        {!isloading && filteredData.length !== 0 && !error && (
          <>
            <div className="wrapper">
              <div
                style={{ paddingBottom: "2rem" }}
                className="searchBarSections"
              >
                <div
                  style={{
                    paddingBottom: "0rem",
                    display: "grid",
                    gridTemplateColumns: "100%",
                  }}
                  className="searchBarSections"
                >
                  <h1 className="vender_Risk_Management_System text_header_color">
                    Vendor Risk Management

                  </h1>

                 
                </div>

                <div style={{cursor:"initial"}} className="print_sagments">
                  <div className="Print_flex">
                    <span style={{ fontWeight: "600", color: "#0000a0" }}>
                      Print
                    </span>
                    <span style={{cursor:"pointer"}} onClick={printSegment}>
                      <FaPrint color="#0000a0" fontSize={26} />
                    </span>
                  </div>
                </div>
              </div>

              {Limit > 2 && (
              <div
              className="floating_div"
                style={{
                  display: "grid",
                  justifyContent: "flex-start",
                  marginBottom: "1rem",
                }}
              >
                <div
                className="comparing_item_div"
                
                >
                  <div className="compareBtn_values">
                    {CompareingVendor &&
                      CompareingVendor.map((item) => {
                        return <div>{capitalizeWords(item)}</div>;
                      })}
                  </div>

                  <div className="compareBtn_sec">
                    {Limit > 2 && <Compareing dataValue={CompareingVendor} />}
                  </div>
                </div>
              </div>
            )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0rem",
                  flexWrap:"wrap",
                  gridGap:"10px"
                }}
              >
                <div className="search-wrapper">
                  <input
                    type="search"
                    name="search-form"
                    id="search-form"
                    autoComplete="off"
                    value={query}
                    className="SearchBox_new"
                    placeholder="Search for Vendor Name"
                    onChange={(e) => {
                      setQuery(e.target.value);
                      handlePageChange({ selected: 1 });
                    }}
                  />
                </div>

                <div style={{ display: "flex", gridGap: "20px" }}>
                  <div className="selectParent select">
                    <select
                      value={filterCountry}
                      onChange={(e) => {
                        setfilterCountry(e.target.value);
                        handlePageChange({ selected: 1 });
                        setfilterCountryToggle(true);
                        filterByundefinedData();
                      }}
                      className="custom-select"
                      aria-label="Filter Countries By Region"
                    >
                      <option value="">Filter By Country Name</option>
                      {strAscending.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>

                  <div className="selectParent select">
                    <select
                      value={filter}
                      onChange={(e) => {
                        setFilter(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select"
                      aria-label="Filter Countries By Region"
                    >
                      <option value="">Filter By Region</option>
                      {filterCountryToggle === true ? (
                        <>
                          {filter_items_by_region_sort.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </>
                      ) : (
                        <>
                          {strAscending_Region.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </>
                      )}
                    </select>
                    <span className="focus"></span>
                  </div>

                  <div className="selectParent select">
                    <select
                      value={filterInherent}
                      onChange={(e) => {
                        setfilterInherent(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select"
                      aria-label="Filter Countries By Region"
                    >
                      <option value="">Filter By Inherent</option>
                      {filter_inher.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>

                  <div className="selectParent select">
                    <select
                      value={filterBsore}
                      onChange={(e) => {
                        setfilterBscore(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select"
                      aria-label="Filter Countries By Region"
                    >
                      <option value="">Filter By BScore</option>
                      {filter_Bitsight.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>


                  <div>
                    <button
                      style={{
                        border: "1px solid #d0d8e2",
                        borderRadius: " 10px",
                        padding: "10px",
                        background: "#253978",
                        color: "white",
                      }}
                      onClick={resetAll}
                    >
                      Clear All
                    </button>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                  }}
                >
                  Total number of Vendors{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#253978",
                    }}
                  >
                    {search(filteredData).length}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <div>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handelepageclick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={currentPage - 1}
                    />
                  </div>
                </div>
              </div>

              {isloadingTable && (
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <th
                        style={{
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "2%",
                          textAlign: "center",
                        }}
                      ></th>

                      <th
                        style={{
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "29%",
                        }}
                      >
                        Vendor Name
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "13%",
                        }}
                      >
                        Vendor ID
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "12%",
                        }}
                      >
                        Region
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "12%",
                        }}
                      >
                        Country
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "14%",
                        }}
                      >
                        Residual Risk
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "14%",
                        }}
                      >
                        Inherent Risk
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "10%",
                        }}
                      >
                        Bitsight
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "4%",
                        }}
                      >
                        Rating
                      </th>
                    </tr>

                    {Array.from({ length: 7 }, (_, i) => (
                      <tr>
                        <td>
                          <Skeleton width="100%" height={20} count={1} />
                        </td>
                        <td>
                          <Skeleton width="100%" height={20} count={1} />
                        </td>
                        <td>
                          <Skeleton width="100%" height={20} count={1} />
                        </td>
                        <td>
                          <Skeleton width="100%" height={20} count={1} />
                        </td>
                        <td>
                          <Skeleton width="100%" height={20} count={1} />
                        </td>
                        <td>
                          <Skeleton width="100%" height={20} count={1} />
                        </td>

                        <td>
                          <Skeleton width="100%" height={20} count={1} />
                        </td>
                        <td>
                          <Skeleton width="100%" height={20} count={1} />
                        </td>
                        <td>
                          <Skeleton width="100%" height={20} count={1} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {!isloadingTable && (
                <table
                  id="DataTable"
                  ref={componentRef_1}
                  style={{ width: "100%" }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "2%",
                          textAlign: "center",
                        }}
                      ></th>

                      <th
                        style={{
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "29%",
                        }}
                      >
                        Vendor Name
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "13%",
                        }}
                      >
                        Vendor ID
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "12%",
                        }}
                      >
                        Region
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "12%",
                        }}
                      >
                        Country
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "14%",
                        }}
                      >
                        Residual Risk
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "14%",
                        }}
                      >
                        Inherent Risk
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "10%",
                        }}
                      >
                        Bitsight
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "4%",
                        }}
                      >
                        Rating
                      </th>
                    </tr>

                    {search(filteredData).length !== 0 && (
                      <>
                        {currentData.map((item, index) => (
                          <tr>
                            <td>
                              <div class="form-check">
                                <input
                                  ref={(element) => { ref.current[index] = element }}
                                  class="form-check-input checkbox"
                                  type="checkbox"
                                  value={item.vnct}
                                  id={`flexCheckDefault_` + index}
                                  defaultChecked={false}
                                  onChange={handleCheck}
                                />
                              </div>
                            </td>

                            <td
                              style={{ fontWeight: "600" }}
                              class="vendor_risk"
                            >
                              <Link
                                title={capitalizeWords(item.vnct)}
                                style={{
                                  width: "100%",
                                  background: "transparent",
                                }}
                                to={`/vendor?name=${item.vnct}`}
                              >
                                {capitalizeWords(item.vnct)}
                              </Link>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                }}
                              >
                                <span class="vendor_risk_items_cls">
                                  {item.VendorID}
                                </span>
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                }}
                              >
                                <span class="vendor_risk_items_cls">
                                  {item.Region === " " ? "N/A" : item.Region}
                                </span>
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                }}
                              >
                                <span class="vendor_risk_items_cls">
                                  {item.Country}
                                </span>
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                }}
                              >
                                {item.Rating === "Low" ? (
                                  <div class="vendor_risk_items_low vendor_loc itemsFlex">
                                    <span>{item.Rating}</span>
                                  </div>
                                ) : item.Rating === "High" ? (
                                  <div class="vendor_risk_items_high vendor_loc itemsFlex">
                                    <span>{item.Rating}</span>
                                  </div>
                                ) : item.Rating === "Moderate" ? (
                                  <div class="vendor_risk_items_modrate vendor_loc itemsFlex">
                                    <span>{item.Rating}</span>
                                  </div>
                                ) : item.Rating === "Very Low" ? (
                                  <div class="vendor_risk_items_veryLow vendor_loc itemsFlex">
                                    <span>{item.Rating}</span>
                                  </div>
                                ) : (
                                  <div class="vendor_risk_items_critical vendor_loc itemsFlex">
                                    <span>{item.Rating}</span>
                                  </div>
                                )}
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                }}
                              >
                                {item.InherentRiskRating === "Low" ? (
                                  <div class="vendor_risk_items_low vendor_loc itemsFlex">
                                    <span>{item.InherentRiskRating}</span>
                                  </div>
                                ) : item.InherentRiskRating === "High" ? (
                                  <div class="vendor_risk_items_high vendor_loc itemsFlex">
                                    <span>{item.InherentRiskRating}</span>
                                  </div>
                                ) : item.InherentRiskRating === "Moderate" ? (
                                  <div class="vendor_risk_items_modrate vendor_loc itemsFlex">
                                    <span>{item.InherentRiskRating}</span>
                                  </div>
                                ) : item.InherentRiskRating === "Very Low" ? (
                                  <div class="vendor_risk_items_veryLow vendor_loc itemsFlex">
                                    <span>{item.InherentRiskRating}</span>
                                  </div>
                                ) : (
                                  <div class="vendor_risk_items_critical vendor_loc itemsFlex">
                                    <span>{item.InherentRiskRating}</span>
                                  </div>
                                )}
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                }}
                              >
                                <span className="vendor_risk_items_cls">
                                  {item.BitSightScore}
                                </span>
                              </span>
                            </td>

                            <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                }}
                              >
                                {item.Rating === "Low" ? (
                                  <FaArrowDown color="green" fontSize={14} />
                                ) : item.Rating === "High" ? (
                                  <FaArrowUp color="red" fontSize={14} />
                                ) : item.Rating === "Moderate" ? (
                                  <FaArrowUp color="#FFA500" fontSize={14} />
                                ) : item.Rating === "Very Low" ? (
                                  <FaArrowDown color="green" fontSize={14} />
                                ) : item.Rating === "Critical" ? (
                                  <FaArrowUp color="red" fontSize={14} />
                                ) : (
                                  <FaTimes color="red" fontSize={14} />
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}

                    {search(filteredData).length === 0 && (
                      <tr style={{ textAlign: "center" }}>
                        <td
                          style={{ textAlign: "center", color: "red" }}
                          rowSpan={4}
                          colSpan={9}
                        >
                          No Data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}

        {!isloading && filteredData.length === 0 && !error && (
          <div style={{ height: "100%" }}>
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                height: "100%",
              }}
            >
              <div style={{ display: "grid", justifyContent: "center" }}>
                <img width={200} alt="nodata" src={noDataAvailable} />
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#252978",
                }}
              >
                Opps no data available
              </div>
            </div>
          </div>
        )}

        {error && (
          <div className="PlaseWait">
            <div className="spinner_class">
              {`There is a problem fetching the post data ${iserror}`}
            </div>
            <div>Please try after sometime</div>
          </div>
        )}

        {alertMessage && (
        <div style={{ position: "fixed", right: "40px", top: "90px" }}>
        <div class="alert alert-danger" role="alert">
              Please select only 2 options for comparing

            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RiskCatagory;
