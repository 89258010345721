import React, { useEffect } from "react";
import { FaChartBar, FaEdit, FaNetworkWired, FaThLarge } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../../css/Style.css";
import "../../css/responsive.css";
import $ from 'jquery'

import Logout from "../auth/Logout";

export default function SideMenu() {

  const getUrlData = () =>{
    const getURl = new URL(window.location.href);
   // const Idfetch = url.searchParams.get("admClientId");
  // console.log(getURl);
    const pathValue = getURl.pathname;
    //console.log(pathValue);
    if(pathValue === '/'){
      $("#HomePage").addClass("activeMenu")
    }
    if(pathValue === '/Workflow'){
      $("#WorkflowPage").addClass("activeMenu")
    }
      
  }

  useEffect(() => {
    getUrlData()
  });
  return (
    <div>
      <div id="mySidenav" className="sideNav common_bg_color">
        <div className="sideNavMenu">
          <div className="sideNavMenu_grid">
          
            <Link to={"/"}>
              <div title="Home"  id="HomePage"  style={{padding:"7px",paddingLeft:"15px",paddingRight:'15px'}} className="items_menu">
                <div style={{display:"grid",gridGap:"7px"}}>
                  <div style={{display:"grid",justifyContent:"center"}}>
                    <FaThLarge  fontSize={24} />
                  </div>
                  
                  <span className='Logout_text_cls' style={{fontSize:"12px",fontWeight:"600"}}>Home</span>

                </div>
                
                <div className="item_menuText">Home</div>
              </div>
            </Link>
            
            <Link to={"/Workflow"}>
              <div title="Workflow"  id="WorkflowPage"  style={{padding:"6px"}} className="items_menu">
                <div style={{display:"grid",gridGap:"7px"}}>
                  <div style={{display:"grid",justifyContent:"center"}}>
                    <FaNetworkWired  fontSize={24} />
                  </div>
                  
                  <span className='Logout_text_cls' style={{fontSize:"12px",fontWeight:"600"}}>Workflow</span>

                </div>
                
                <div className="item_menuText">Workflow</div>
              </div>
            </Link>

          
          
          <>
             <Logout/>
            </>
            
          </div>
        </div>
      </div>
    </div>
  );
}
