import React, { useEffect, useState } from "react";
import { FaInfoCircle, FaPlus, FaMinus } from "react-icons/fa";
import $ from "jquery";

const TechnicalInformations = (props) => {
  const [TogglePlusIons, setTogglePlusIons] = useState(true);
  const CollapseButton = () => {
    setTogglePlusIons((prev) => !prev);
    $("#Technical_info_id").slideToggle(0);
  };

  return (
    <div>
      <div id="Technical_Informations">
        <div
          className="Basic_Informations"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div>{props.title}</div>
          <div
            className="toggleIonsPlusicons"
            style={{ cursor: "pointer", paddingRight: "10px" }}
            onClick={CollapseButton}
          >
            {TogglePlusIons ? (
              <FaMinus color="#c1c1c1" fontSize={20} />
            ) : (
              <FaPlus color="#c1c1c1" fontSize={20} />
            )}
          </div>
        </div>
        <br />
        <div id="Technical_info_id" className="Active_contact">
          <table>
            <tbody>
              <tr>
                <td colSpan={2}>Technical informations</td>
              </tr>

              <tr>
                <th>
                  <span className="flexbody">
                    <span> Segment Associations</span>
                    <span className="grid_body">
                      <FaInfoCircle className="infoCircleico" fontSize={18} />
                      <span className="tooltiptext">Snow</span>
                    </span>
                  </span>
                </th>
                <td colSpan={2}>{props.Segment_Associations}</td>
              </tr>

              <tr>
                <th style={{ width: "35%" }}> Saas Address</th>
                <td colSpan={2}>{props.Saas_Address}</td>
              </tr>

              <tr>
                <th> Type of Data classifications - Pii, Pci, Hc flags etc </th>
                <td colSpan={2}>{props.Type_of_Data}</td>
              </tr>

              <tr>
                <th>EA#</th>
                <td colSpan={2}>{props.EA}</td>
              </tr>

              <tr>
                <th> PIA</th>
                <td colSpan={2}>{props.PIA}</td>
              </tr>

              <tr>
                <th> S2s VPN</th>
                <td colSpan={2}>{props.s2s}</td>
              </tr>

              <tr>
                <th> VDI</th>
                <td colSpan={2}>{props.VDI}</td>
              </tr>

              <tr>
                <th> FW rules</th>
                <td colSpan={2}>{props.fw}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {TogglePlusIons ? <span></span> : <span>Please click the right ( + ) icons for view the {props.title}</span>}

      </div>
    </div>
  );
};

export default TechnicalInformations;
