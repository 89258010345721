import React, { useEffect, useState } from 'react'
import { useOktaAuth } from "@okta/okta-react";
import Preloadimg from "../spinner/Loading";

const AfterLogin = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const [PageAuth, setPageAuth] = useState(false);

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            setPageAuth(false);
        } else {
            setPageAuth(true);
            const emailData = authState.idToken.claims
            localStorage.setItem("myemail", emailData.email);
            console.log(`${emailData.email} Login Success`);	
        }
      }, [authState, oktaAuth]);

      const url = new URL(window.location.href);

  return (
    <div>
      {!PageAuth && url.pathname === "/login/callback" && (
        <div style={{display:"grid",justifyContent:"center",textAlign:"center",alignContent:"center",alignItems:"center",height:"100vh"}} className="pageLoading">
          <Preloadimg/>
          <div>Please wait...</div>
        </div>
      )}
    </div>
  );
}

export default AfterLogin
