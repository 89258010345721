import React, { useState } from "react";
import { FaInfoCircle, FaPlus, FaMinus } from "react-icons/fa";
import $ from 'jquery';

const Description = (props) => {
  const [TogglePlusIons, setTogglePlusIons] = useState(true);
  const CollapseButton = () => {
    setTogglePlusIons((prev) => !prev)
    $("#dec_text_id").slideToggle(0);
  };

  return (
    <div>
      <div id="descService_id" className="desc_services">
        <div
          className="Basic_Informations"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <span className="flexbody">
            <span>{props.title}</span>
            <span className="grid_body">
              <FaInfoCircle className="infoCircleico" fontSize={23} />
              <span className="tooltiptext">Snow</span>
            </span>
          </span>
          <div
            className="toggleIonsPlusicons"
            style={{ cursor: "pointer", paddingRight: "10px" }}
            onClick={CollapseButton}
          >
            {TogglePlusIons ? (
              <FaMinus color="#c1c1c1" fontSize={20} />
            ) : (
              <FaPlus color="#c1c1c1" fontSize={20} />
            )}
          </div>
        </div>
        <br/>
        <div id="dec_text_id" className="dec_text">
          <span className="das_services">{props.des}</span>
        </div>
       
        {TogglePlusIons ? <span></span> : <span>Please click the right ( + ) icons for view the {props.title}</span>}

      </div>
    </div>
  );
};

export default Description;
